import { graphql } from 'relay-hooks';

export const ValidatedDeviceListQuery = graphql`
    query ValidatedDeviceListQuery($envdAccountId: String!, $consignmentNumber: String!) {
        devices(envdAccountId: $envdAccountId, consignmentNumber: $consignmentNumber) {
            value {
                nLISID
                rFID
                registeredTo
                species
                status
                deceased
            }
            totalDevices
        }
    }
`;

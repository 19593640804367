import React, { createContext, useContext, useState } from 'react';
import { FileWithPath } from 'react-dropzone'; // Import FileWithPath type

export type ValidatedDevice = {
    nLISID: string;
    rFID: string;
    registeredTo: string;
    species: string;
    status: string;
    deceased: boolean;
}

export type ValidatedDeviceResponse = {
    value: Set<ValidatedDevice>,
    totalDevices: number,
}

// Define the types for the context
type DevicesContextType = {
    acceptedFile: FileWithPath[]; // Explicitly type the acceptedFile array
    setAcceptedFile: (files: FileWithPath[]) => void;
    deviceIds: Set<string>;
    setDeviceIds: (deviceIds: Set<string>) => void;
    errorMessage: string;
    setErrorMessage: (errMsg: string) => void;
    setValidatedDevices: (res: ValidatedDeviceResponse) => void;
    validatedDevices: ValidatedDeviceResponse;
};

const DevicesContext = createContext<DevicesContextType | undefined>(undefined);

// Hook to access the context
export const useDevicesContext = () => {
    const context = useContext(DevicesContext);
    if (!context) {
        throw new Error('useDevicesContext must be used within a FileProvider');
    }
    return context;
};

export const DevicesProvider = ({ children }: { children: React.ReactNode }) => {
    const [acceptedFile, setAcceptedFile] = useState<FileWithPath[]>([]);
    const [deviceIds, setDeviceIds] = useState<Set<string>>(new Set());
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [validatedDevices, setValidatedDevices] = useState<ValidatedDeviceResponse>({ value: new Set<ValidatedDevice>(), totalDevices: 0 });

    return <DevicesContext.Provider value={{ acceptedFile, setAcceptedFile, deviceIds, setDeviceIds, errorMessage, setErrorMessage, validatedDevices, setValidatedDevices }}>{children}</DevicesContext.Provider>;
};

import { CheckIcon, ChevronDownIcon, ErrorIcon, FailedIcon, SuccessIcon, WarningIcon } from '@assets/icons';
import { ValidatedDevice } from '@common/context/DevicesContext';
import Button from '@components/Button';
import ContextMenu from '@components/Form/ContextMenu';
import Input from '@components/Form/Input';
import Tooltip from '@components/Form/Tooltip';
import Modal from '@components/Modal';
import Tag from '@components/Tag';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import GetDeviceContextMenu, { OPTIONS } from '@containers/Devices/Device-Context-Menu';
import useDeviceManager from '@effects/useDeviceManager';
import { deviceStatusEnumToColor, deviceStatusEnumToName } from '@utils/enum-transformers';
import { DeviceResponseStatus, DeviceValidationStatus, ValidationApiAction } from '@utils/enums';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';

interface DeviceRowProps {
    validatedDevice: ValidatedDevice;
    consignment: ConsignmentDetailQueryResponse['consignment']
}
const DeviceRow: React.FC<DeviceRowProps> = ({ validatedDevice, consignment }) => {
    const { getValidationStatus, validateAndAddDevices } = useDeviceManager();
    const [loading, setLoading] = useState(false);
    const [optionState, setOptionState] = useState('');
    const deleteModalRef = useRef<ConfirmModalRef>();
    const [updatedDeviceId, setUpdatedDeviceId] = useState([validatedDevice.nLISID]);
    const getValidationStatusTag = (): { icon: React.ReactNode, tooltipText: string } => {
        switch (getValidationStatus(validatedDevice, consignment)) {
            case DeviceValidationStatus.ERROR:
                return {
                    icon: <FailedIcon alt="Error" />,
                    tooltipText: `Validation found errors, this specific tag won't transfer. Please replace or delete it.`
                };
            case DeviceValidationStatus.VALIDATED:
                return {
                    icon: <SuccessIcon alt="Success" />,
                    tooltipText: `Validation completed successfully with no error, or warnings. All in order, allowing you to proceed smoothly with the transfer.`
                };
            case DeviceValidationStatus.WARNING:
                return {
                    icon: <ErrorIcon style={{ color: '#FDB714' }} alt="Warning" />,
                    tooltipText: `Validation has some warnings. These won't stop the transfer, but resolving them beforehand is helpful.`
                };
        }
    };

    const RegisteredPicWarningText = () => {
        if (validatedDevice.registeredTo) {

            if (validatedDevice.registeredTo.startsWith('EUSY')) {
                return 'THIS IS A SALEYARD PIC';
            }
            if (validatedDevice.registeredTo !== consignment?.origin.pic) {
                return 'NOT SPECIFY PIC';
            }
        }
        return null;
    };

    const LivestockWarningText = () => {
        if (validatedDevice.species) {

            if (validatedDevice.species.toLowerCase() !== consignment?.species.toLowerCase()) {
                return 'WRONG SPECIES';
            }
        }
        return null;
    };

    const DeviceIdNotFoundErrorText = () => {
        if (validatedDevice.status.toLowerCase() === DeviceResponseStatus.NOT_FOUND.toLowerCase()) {
            return 'INVALID ID, PLEASE RECHECK';
        }
        return null;
    };

    const getClassName = (warning: boolean, error: boolean) => {
        return classNames(
            'cell-content"',
            {
                'cell-content--Warning': warning,
                'cell-content--Inactive': !warning && validatedDevice.status === DeviceResponseStatus.INACTIVE,
                'cell-content--Error': error,
            }
        );
    };

    const handleSave = async () => {
        setLoading(true);
        await validateAndAddDevices(consignment?.number as string, ValidationApiAction.EDIT, updatedDeviceId, [validatedDevice.nLISID ?? validatedDevice.rFID]);
        setLoading(false);
        setOptionState('');
    };
    return (
        <div className={classNames('tbl-row')} key={validatedDevice?.nLISID ?? validatedDevice?.rFID}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .tbl-row {
                    .tbl-cell-30 {
                        div {
                            width: 100%;
                        }
                    }

                    @media (max-width: $md-max) {
                        border: 1px solid $color-line;
                        border-radius: $border-radius;
                        background: $color-white;
                        margin: grid(4) 0;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 80%);
                        }
                    }
                }

                .tbl-unsupported {
                    // background-color: $color-warn;
                }

                .title {
                    :global(.Button--Link) {
                        text-align: left;
                    }
                }

                .cell-content {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-content: flex-start;
                    :global(.Button--Link) {
                        justify-content: flex-start;
                        margin-top: grid(1);

                        :global(svg) {
                            width: grid(4);
                            height: grid(4);
                        }
                    }
                    &--Warning {
                        h4, p{
                            color: rgba($color-warn, 1);
                        }
                        h4{
                            font-weight: 500;
                        }
                        p{
                            font-size: 10px;
                        }
                    }
                    &--Error {
                        h4, p{
                            color: rgba($color-error, 1);
                        }
                        h4{
                            font-weight: 500;
                        }
                        p{
                            font-size: 10px;
                        }
                    }
                    &--Inactive {
                        h4, p{
                            color: rgba($color-subtitle, 1);
                        }
                    }
                }

                .responsive-heading {
                    @media (max-width: $md-max) {
                        flex-direction: column;
                        align-items: flex-start !important;

                        .mobile-header-options {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                        }
                    }
                }

                
                .save-btn {
                        color: $color-secondary;
                        font-size: 17px;
                        line-height: 20px;
                        font-weight: 500;
                    }
                .check-icon {
                    color: $color-secondary;
                }
                .check-icon:hover {
                    cursor: pointer;
                }
                
                .warning-icon {
                    :global(svg) {
                        width: $icon-lg;
                        height: $icon-lg;
                        color: $color-error;
                    }
                }

                .gap {
                    gap: 10px;
                }
            `}</style>

            <div className="tbl-cell-10 responsive-heading flex-center-row flex-center" data-cy="device-validation">
                <Tooltip
                    text={getValidationStatusTag().tooltipText}
                    children={getValidationStatusTag().icon}
                    placement='right'
                />
            </div>

            <div className="tbl-cell-20" data-cy="device-nlisd">
                <div className="cell-title">Device Nlisid</div>
                <div className={getClassName(false, Boolean(validatedDevice.nLISID && DeviceIdNotFoundErrorText()))}>
                    {
                        optionState !== OPTIONS.EDIT_NLISID
                            ? <>
                                <h4>{validatedDevice.nLISID}</h4>
                                <p>{validatedDevice.nLISID && DeviceIdNotFoundErrorText()}</p>
                            </>
                            : <Input onChange={(e) => {
                                setUpdatedDeviceId([e.target.value.toString()]);
                            }} style={{ fontSize: 14, padding: 12, textAlign: 'center' }} value={updatedDeviceId.join()} />
                    }
                </div>
            </div>
            <div className="tbl-cell-20" data-cy="device-rfid">
                <div className="cell-title">Device Rfid</div>
                <div className={getClassName(false, Boolean(validatedDevice.nLISID && DeviceIdNotFoundErrorText()))}>
                    {
                        optionState !== OPTIONS.EDIT_RFID
                            ? <>
                                <h4>{validatedDevice.rFID}</h4>
                                <p>{validatedDevice.rFID && DeviceIdNotFoundErrorText()}</p>
                            </>
                            : <Input onChange={(e) => {
                                setUpdatedDeviceId([e.target.value.toString()]);
                            }} style={{ fontSize: 14, padding: 12, textAlign: 'center' }} value={updatedDeviceId.join()} />
                    }
                </div>
            </div>
            <div className="tbl-cell-15" data-cy="device-livestock">
                <div className="cell-title">Device Livestock</div>
                <div className={getClassName(Boolean(LivestockWarningText()), false)}>
                    <h4>{validatedDevice.species}</h4>
                    <p>{LivestockWarningText()}</p>
                </div>
            </div>
            <div className="tbl-cell-15" data-cy="device-registered-pic">
                <div className="cell-title">Device Registered To</div>
                <div className={getClassName(Boolean(RegisteredPicWarningText()), false)}>
                    <h4>{validatedDevice.registeredTo}</h4>
                    <p>{RegisteredPicWarningText()}</p>
                </div>
            </div>
            <div className="tbl-cell-15" data-cy="device-status">
                <div className="cell-title">Device Status</div>
                <div className="cell-content">
                    <Tag
                        checkIcon={false}
                        tagType={deviceStatusEnumToColor(validatedDevice.status as DeviceResponseStatus)}
                        text={deviceStatusEnumToName(validatedDevice.status as DeviceResponseStatus)}
                    />
                </div>
            </div>
            <div className="tbl-cell-10" data-cy="device-deceased">
                <div className="cell-title">Device Deceased</div>
                <div className={getClassName(validatedDevice.deceased, false)}>
                    <h4>{validatedDevice.status !== DeviceResponseStatus.NOT_FOUND && (validatedDevice.deceased ? 'YES' : 'NO')}</h4>
                </div>
            </div>
            <div className="tbl-cell-15" data-cy="nlisd">
                {optionState !== OPTIONS.EDIT_NLISID && optionState !== OPTIONS.EDIT_RFID
                    ? <ContextMenu
                        options={GetDeviceContextMenu(validatedDevice, setOptionState, deleteModalRef)}
                        icon={<ChevronDownIcon />}
                        buttonText="Options"
                    />
                    : <Button
                        buttonType={'tertiary'}
                        buttonSize={'normal'}
                        onClick={handleSave}
                        disabled={loading}
                    >
                        <span className="save m-r-8">Save</span>
                        <CheckIcon />
                    </Button>
                }
            </div>
            <ConfirmModal
                actions={[
                    {
                        style: 'secondary',
                        text: 'Cancel',
                        buttonSize: 'full-width',
                        action: async () => {
                            deleteModalRef.current?.hide();
                        },
                    },
                    {
                        style: 'delete',
                        text: 'Delete',
                        buttonSize: 'full-width',
                        action: async () => {
                            setLoading(true);
                            await validateAndAddDevices(consignment?.number as string, ValidationApiAction.DELETE, undefined, [(validatedDevice.nLISID ?? validatedDevice.rFID)]);
                            setLoading(false);
                            deleteModalRef.current?.hide();
                        },
                    },
                ]}
                ref={deleteModalRef}
                modalId={`confirm-modal`}
            >
                <div className="flex-center-row warning-icon gap" >
                    <WarningIcon />
                    <h2>Delete the device</h2>
                </div>
                <div className='m-t-20'>
                    <p> Once you delete, you wil no longer be able to view it on your web. </p>
                    <p className='m-t-12'>
                        Are you sure you want to proceed with this action?
                    </p>
                </div>
            </ConfirmModal>
            <Modal id="loader" show={loading} loader={loading} />
        </div>
    );
};

export default DeviceRow;

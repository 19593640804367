import FormRow from '@components/Form/FormRow';
import useEventListener from '@effects/useEventListenter';
import classnames from 'classnames';
import React from 'react';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    errorText?: string;
    labelText?: string;
    helperText?: string;
    placeholderText?: string;
    isValid?: boolean;
    isReadOnly?: boolean;
    regex?: string | RegExp;
}

const MultilineTextArea: React.FunctionComponent<TextAreaProps> = ({ errorText, labelText, helperText, placeholderText, isValid, isReadOnly, rows, className, children, ...textAreaProps }) => {
    const isError = isValid === false;
    const isSuccess = isValid === true;

    useEventListener('resize', () => {
        if (/Android/.test(navigator.appVersion)) {
            const element = document.activeElement;
            if (element) {
                if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
                    element.scrollIntoView();
                }
            }
        }
    });
    const renderMultiLine = (
        <>
            <textarea
                id="multiline-input"
                rows={rows}
                style={{
                    width: '100%',
                    padding: '8px',
                    fontSize: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    resize: 'vertical', // Allow resizing vertically
                    overflowY: 'auto', // Show vertical scrollbar if needed
                    whiteSpace: 'pre-wrap', // Ensure new lines are handled
                    wordWrap: 'break-word', // Prevent words from spilling over
                }}

                {...textAreaProps}
            />
        </>
    );

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .form-element,
                :global(input) {
                    font-size: grid(4);
                    width: 100%;
                    border-radius: $border-radius;
                    border: 1px solid $color-border;
                    vertical-align: middle;
                    height: grid(11);
                    padding: grid(3) grid(8) grid(3) grid(3);

                    &:focus {
                        border-color: $color-secondary;
                    }
                    &::placeholder {
                        @include text-small;
                    }

                    &:read-only:not[type='file'] {
                        background-color: $color-line;
                        cursor: not-allowed;
                    }

                    @media (prefers-color-scheme: dark) {
                        color: lighten($color-copy, 100%);
                        background-color: darken($color-white, 90%);
                    }
                }

                .input-container {
                    position: relative;
                    max-width: $multiline-input-maxwidth;
                    margin-bottom: grid(0.5);

                    &.with-icon {
                        :global(svg) {
                            position: absolute;
                            right: 7px;
                            top: 12px;
                            width: $icon-sm;
                            height: $icon-sm;
                            pointer-events: none;
                        }
                    }

                    &.with-success {
                        input {
                            border-color: $color-success;
                        }
                    }

                    &.with-error {
                        input {
                            border-color: $color-error;
                        }
                    }
                }
            `}</style>

            <FormRow errorText={errorText} isValid={isValid} helperText={helperText} labelText={labelText} required={textAreaProps.required} readOnly={isReadOnly}>
                <div
                    className={classnames('input-container', className, {
                        'with-success': isSuccess,
                        'with-error': isError,
                    })}
                >
                    {!isReadOnly ? <>{renderMultiLine}</> : <p>{textAreaProps.value ? textAreaProps.value : `Not answered`}</p>}
                </div>
                {children}
            </FormRow>
        </>
    );
};

export default MultilineTextArea;

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ActionType = "ADD" | "DELETE" | "EDIT" | "%future added value";
export type DeviceStatus = "ACTIVE" | "INACTIVE" | "NOT_FOUND" | "%future added value";
export type SaveOrUpdateDevicesInput = {
    clientMutationId?: string | null;
    number: string;
    deviceIds?: Array<string | null> | null;
    deviceIdsToBeRemoved?: Array<string | null> | null;
    action: ActionType;
};
export type saveOrUpdateDevicesMutationVariables = {
    input: SaveOrUpdateDevicesInput;
    envdAccountId: string;
};
export type saveOrUpdateDevicesMutationResponse = {
    readonly saveOrUpdateDevices: {
        readonly data: {
            readonly value: ReadonlyArray<{
                readonly nLISID: string | null;
                readonly rFID: string | null;
                readonly registeredTo: string | null;
                readonly species: string | null;
                readonly status: DeviceStatus | null;
                readonly deceased: boolean | null;
            } | null> | null;
            readonly totalDevices: string | null;
        } | null;
    } | null;
};
export type saveOrUpdateDevicesMutation = {
    readonly response: saveOrUpdateDevicesMutationResponse;
    readonly variables: saveOrUpdateDevicesMutationVariables;
};



/*
mutation saveOrUpdateDevicesMutation(
  $input: SaveOrUpdateDevicesInput!
  $envdAccountId: String!
) {
  saveOrUpdateDevices(input: $input, envdAccountId: $envdAccountId) {
    data {
      value {
        nLISID
        rFID
        registeredTo
        species
        status
        deceased
      }
      totalDevices
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SaveOrUpdateDevices",
    "kind": "LinkedField",
    "name": "saveOrUpdateDevices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceType",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DevicesType",
            "kind": "LinkedField",
            "name": "value",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nLISID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rFID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registeredTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "species",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deceased",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDevices",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "saveOrUpdateDevicesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "saveOrUpdateDevicesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "713d24bbbcc55e07e9c202e39ecdf33a",
    "id": null,
    "metadata": {},
    "name": "saveOrUpdateDevicesMutation",
    "operationKind": "mutation",
    "text": "mutation saveOrUpdateDevicesMutation(\n  $input: SaveOrUpdateDevicesInput!\n  $envdAccountId: String!\n) {\n  saveOrUpdateDevices(input: $input, envdAccountId: $envdAccountId) {\n    data {\n      value {\n        nLISID\n        rFID\n        registeredTo\n        species\n        status\n        deceased\n      }\n      totalDevices\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '135d57fe03a4bca6d49e9edba366bbd0';
export default node;

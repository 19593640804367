import React from 'react';

interface ToggleSwitchProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    switchSize?: 'small' | 'medium' | 'large';
}

const ToggleSwitch: React.FunctionComponent<ToggleSwitchProps> = ({ disabled, onChange, switchSize = 'medium', ...checkboxProps }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .switch {
                    position: relative;
                    display: inline-block;
                    width: ${switchSize === 'small' ? '40px' : switchSize === 'medium' ? '55px' : '70px'};
                    height: ${switchSize === 'small' ? '20px' : switchSize === 'medium' ? '28px' : '35px'};
                }

                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                    border-radius: ${switchSize === 'small' ? '20px' : switchSize === 'medium' ? '28px' : '34px'};
                }

                .slider:before {
                    position: absolute;
                    content: '';
                    height: ${switchSize === 'small' ? '14px' : switchSize === 'medium' ? '20px' : '28px'};
                    width: ${switchSize === 'small' ? '14px' : switchSize === 'medium' ? '20px' : '26px'};
                    left: ${switchSize === 'small' ? '3px' : switchSize === 'medium' ? '4px' : '4px'};
                    bottom: ${switchSize === 'small' ? '3px' : switchSize === 'medium' ? '4px' : '4px'};
                    background-color: white;
                    -webkit-transition: 0.1s;
                    transition: 0.1s;
                    border-radius: 50%;
                }

                input:checked + .slider {
                    background-color: $isc-blue;
                }

                input:focus + .slider {
                    box-shadow: 0 0 1px $isc-blue;
                }

                input:checked + .slider:before {
                    -webkit-transform: translateX(${switchSize === 'small' ? '20px' : switchSize === 'medium' ? '27px' : '36px'});
                    -ms-transform: translateX(${switchSize === 'small' ? '20px' : switchSize === 'medium' ? '27px' : '36px'});
                    transform: translateX(${switchSize === 'small' ? '20px' : switchSize === 'medium' ? '27px' : '36px'});
                }
            `}</style>
            <label className="switch">
                <input type="checkbox" disabled={disabled} onChange={onChange} {...checkboxProps} />
                <span className="slider round"></span>
            </label>
        </>
    );
};

export default ToggleSwitch;

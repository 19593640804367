import { useState } from 'react';

interface HoverableIconProps {
    Icon: React.ElementType;
    defaultColor: string;
    hoverColor: string;
}

const HoverableIcon: React.FC<HoverableIconProps> = ({ Icon, defaultColor, hoverColor }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Icon
            style={{
                color: isHovered ? hoverColor : defaultColor,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        />
    );
};

export default HoverableIcon;

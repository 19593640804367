import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';
import { IDeviceValidation } from 'src/typings/device';

import { SaveOrUpdateDevicesInput } from './__generated__/saveOrUpdateDevicesMutation.graphql';

export const saveOrUpdateDevicesMutation = graphql`
    mutation saveOrUpdateDevicesMutation($input: SaveOrUpdateDevicesInput!, $envdAccountId: String!) {
        saveOrUpdateDevices(input: $input, envdAccountId: $envdAccountId) {
            data {
                value {
                    nLISID
                    rFID
                    registeredTo
                    species
                    status
                    deceased
                }
                totalDevices
            }
        }
    }
`;

const commitAsync = async (mutate: any, devices: SaveOrUpdateDevicesInput, envdAccountId: String): Promise<Partial<IDeviceValidation>> => {
    MLALogger.Log(['validating devices', 'commitAsync', 'Sending'], { devices });
    try {
        const data = await mutate({
            variables: { input: devices, envdAccountId },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['saveOrUpdateDevices', 'commitAsync'], { response, error });
                if (error) {
                    MLALogger.Log(['saveOrUpdateDevices', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['saveOrUpdateDevices', 'commitAsync'], { response, data: response.saveOrUpdateDevices.data, request: devices });
                }
            },
        });

        return data.saveOrUpdateDevices.data;
    } catch (error) {
        MLALogger.Log(['saveOrUpdateDevices'], error, 'error');
        throw error;
    }
};

export default commitAsync;

import { DateIcon, DeviceTransferFailedIcon, InfoIcon } from '@assets/icons';
import { useDevicesContext, ValidatedDevice } from '@common/context/DevicesContext';
import { useMovementContext } from '@common/context/MovementContext';
import { useUserState } from '@common/context/userContext';
import Button, { ButtonType } from '@components/Button';
import Tooltip from '@components/Form/Tooltip';
import Loader from '@components/Loader';
import Modal from '@components/Modal';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import { containsDeprecatedForms } from '@containers/Consignments/ConsignmentHelper';
import { cleanConsignmentPIC } from '@containers/Consignments/mutations/createOrSaveConsignment';
import { ValidatedDeviceListQuery as DeviceGqlQuery } from '@containers/Consignments/queries/__generated__/ValidatedDeviceListQuery.graphql';
import { ConsignmentEditQuery } from '@containers/Consignments/queries/ConsignmentEdit';
import { ValidatedDeviceListQuery } from '@containers/Consignments/queries/ValidatedDeviceListQuery';
import useDeviceManager from '@effects/useDeviceManager';
import useMovement from '@effects/useMovement';
import { useWindowWidth } from '@effects/useWindowWidth';
import { UNKNOWN_PIC } from '@utils/constants';
import { speciesEnumToName } from '@utils/enum-transformers';
import { ConsignmentSpecies, ValidationApiAction } from '@utils/enums';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery } from 'relay-hooks';

import { AddOrUploadNlisDevices } from './Components/AddManuallyOrUpload/AddOrUploadNlisDevices';
import DeviceList from './Components/DeviceList';
import DevicesMovementToFromSection from './Components/DevicesMovementToFromSection';

const DeviceMovementInfo: React.FC<{ consignment: ConsignmentDetailQueryResponse['consignment'] }> = ({ consignment }) => {
    const { errorMessage, clearFiles, validateAndAddDevices, deviceIds, validatedDevices } = useDeviceManager();
    const { movementActions } = useMovementContext();
    const validationFailedRef = useRef<ConfirmModalRef>();
    const [showDevicesMovementToFrom, setShowDevicesMovementToFrom] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showDevicesList, setShowDevicesList] = useState(true);
    const [canValidateDevices, setCanValidateDevices] = useState(false);
    const [canPerformMovement, setCanPerformMovement] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();
    const width = useWindowWidth();
    const onTabSwitch = (index: number) => {
        setActiveTab(index);
        clearFiles();
    };

    // headers
    // create nlis movement
    // add nlis device
    // preview
    // nlis devices added
    // couple of more
    const DeviceMovementInfoHeader = () => {
        const [headingText, setHeadingText] = useState('');

        useEffect(() => {
            const getHeadingText = () => {
                let text = '';

                if (validatedDevices.totalDevices > 0) {
                    text = 'NLIS Devices Added';
                } else if (!showDevicesMovementToFrom) {
                    text = 'Add NLIS Devices';
                } else {
                    text = 'Create NLIS Movement';
                }

                return text;
            };

            setHeadingText(getHeadingText());
        }, [headingText]);

        return (
            <>
                <style jsx>
                    {`
                        @import 'vars';
                        @import 'utils';
                        @import 'mixins';

                        h1,
                        h3 {
                            margin: grid(10) 0;
                            color: $color-secondary;
                            margin: 10px 0px;
                            @media (max-width: $sm-min) {
                                margin: grid(6) 0;
                            }
                        }
                    `}
                </style>
                {headingText && (
                    <div>
                        <h1 data-cy="welcome-heading">{headingText}</h1>
                        {showDevicesMovementToFrom && <h3>Add Details</h3>}
                    </div>
                )}
            </>
        );
    };

    const LivestockDetails = () => (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .summary-panel {
                    flex: 2 0 65%;

                    .date-info {
                        gap: 10px;
                    }

                    .to-details {
                        border-left: 1px solid $grey-border;
                        position: relative;
                    }
                }

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border: 1px solid $grey-border;
                    border-radius: 4px;
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .icon-info {
                    color: $info-icon;
                }

                .bold {
                    font-weight: 600;
                }

                .grey-text {
                    color: $color-subtitle;
                    cursor: inherit;
                }
            `}</style>
            <div className="summary-panel box m-v-32">
                <div className={`panel-heading ${width > 740 ? 'flex-row flex-between' : 'flex-start-column'} p-v-16 p-h-16`}>
                    <div className="livestock-details-container">
                        <h2>Livestock Details</h2>
                        <div className="flex-center-row m-v-12">
                            <p>Species: {speciesEnumToName(consignment?.species as ConsignmentSpecies)};</p>
                            <p className="flex-center-row p-h-8">
                                Total NLIS devices
                                <div className="icon-info">
                                    <Tooltip
                                        placement="bottom"
                                        title={`What does "Total NLIS devices" mean?`}
                                        text={`"Total NLIS devices" refers to all identifiers used for livestock description in the consignment, including NLIS IDs, RFIDs (ear tags), and rumen devices (cattle only). Ensure consistency between livestock descriptions and your added/upload NLIS devices wherever possible.`}
                                        children={<InfoIcon className={''} style={{ width: 18 }} />}
                                    />
                                </div>
                                : {validatedDevices.value.size};
                            </p>
                            <p className="bold">Total Headcount: {consignment?.heads}</p>
                        </div>
                    </div>
                    <div className="date-info flex-center-row ">
                        <DateIcon />
                        <label htmlFor="" className="info-label grey-text">
                            Movement Date
                        </label>
                        <p className="flex-center-row bold" style={{ margin: 0 }}>{consignment?.movementDate ? new Date(consignment?.movementDate as any).toMLADateString() : ''}</p>
                    </div>
                </div>
            </div>
        </>
    );

    useEffect(() => {
        if (!movementActions) return;

        if (!consignment || !consignment.number) return;

        const actions = movementActions && movementActions[consignment.number];

        if (!actions) return;
        setShowDevicesMovementToFrom(Boolean(actions?.canAddOrEditDevicesForReceiver || actions?.canPerformMovement || actions?.canPreviewMovement));
        setShowDevicesList(
            // (Number(consignment?.numOfAddedDevices ?? 0) > 0
            validatedDevices.value.size > 0 &&
            (Boolean(actions?.canAddOrEditDevicesForProducer) ||
                Boolean(actions?.canViewMovement) ||
                Boolean(actions?.canAddOrEditDevicesForReceiver || actions?.canPerformMovement || actions?.canPreviewMovement))
        );
        setCanValidateDevices(Boolean(actions?.canAddOrEditDevicesForProducer) || Boolean(actions?.canAddOrEditDevicesForReceiver));
        setCanPerformMovement(Boolean(actions?.canPerformMovement));
    }, [consignment, movementActions, validatedDevices.value.size]);

    const handleClick = async () => {
        if (deviceIds.size > 0) {
            if (canValidateDevices) {
                setLoading(true);
                await validateAndAddDevices(consignment?.number as string, ValidationApiAction.ADD);
                setLoading(false);
            } else if (canPerformMovement) {
                // ToDo call movement api
            }
        }
    };

    useEffect(() => {
        if (errorMessage.startsWith('validation failed')) {
            validationFailedRef.current?.show();
        }
    }, [errorMessage]);

    const getButtonType = (): ButtonType => {
        if (deviceIds.size > 0 && errorMessage.length === 0 && ((showDevicesList && canPerformMovement) || (!showDevicesList && canValidateDevices))) {
            return 'primary';
        }
        return 'disable';
    };

    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';

                    .upload-container {
                        width: 70%;
                        min-width: 300px;
                    }

                    .added-device-number {
                        color: $unselected-tab-text;
                    }

                    .failed-icon {
                        :global(svg) {
                            width: $icon-lg;
                            height: $icon-lg;
                            color: $color-error;
                        }
                    }

                    .gap {
                        gap: 10px;
                        font-size: 16px;
                    }

                    .modal-header {
                        color: $color-secondary;
                    }
                `}
            </style>
            <DeviceMovementInfoHeader />
            {showDevicesMovementToFrom ? <DevicesMovementToFromSection consignment={consignment} /> : <LivestockDetails />}
            {showDevicesList ? <DeviceList consignment={consignment} /> : <AddOrUploadNlisDevices activeTab={activeTab} onTabSwitch={onTabSwitch} />}
            {showDevicesList ? <div className="flex-start-row flex-between">
                <Button
                    buttonType="reset"
                >
                    Remove all devices
                </Button>
                <div className='flex-end-row'>
                    <Button
                        buttonType="secondary"
                    >
                        Replace devices
                    </Button>
                    <Button onClick={() => history.push(`/consignments/summary/${consignment?.number}`)} disabled={loading} buttonType={'primary'} buttonSize="medium">
                        Back to summary consignment
                    </Button>
                </div>
            </div>
                : <div className="flex-start-row flex-between">
                    <Button
                        buttonType="secondary"
                        onClick={() => {
                            clearFiles();
                            history.push(`/consignments/summary/${consignment?.number}`);
                        }}
                    >
                        Previous page
                    </Button>
                    <Button onClick={handleClick} disabled={loading} buttonType={getButtonType()} buttonSize="medium">
                        Next step
                    </Button>
                </div>
            }
            <Modal id="loader" show={loading} loader={loading} />
            <ConfirmModal
                actions={[
                    {
                        style: 'secondary',
                        text: 'Back to summary page',
                        buttonSize: 'full-width',
                        action: async () => {
                            validationFailedRef.current?.hide();
                            clearFiles();
                            history.push(`/consignments/summary/${consignment?.number}`);
                        },
                    },
                    {
                        style: 'primary',
                        text: 'Stay on this page',
                        buttonSize: 'full-width',
                        action: async () => {
                            clearFiles();
                            validationFailedRef.current?.hide();
                        },
                    },
                ]}
                ref={validationFailedRef}
                modalId={`confirm-modal`}
            >
                <div className="flex-center-column modal-header p-v-16">
                    <div className="flex-center-row failed-icon gap" style={{ gap: 10, fontSize: 16 }}>
                        <DeviceTransferFailedIcon />
                    </div>
                    <h1>Validation Failed</h1>
                </div>
                <p> Due to a NLIS system error, the validation of attached devices could not be completed. </p>
                <p>
                    Please try resubmitting later. If the issue persists, <a href="https://www.integritysystems.com.au/help/contact-us/">contact support</a> for assistance.
                </p>
            </ConfirmModal>
        </>
    );
};

const DevicesMovement: React.FC = () => {
    const { id } = useParams<any>();
    const [{ user }] = useUserState();
    const history = useHistory();
    const envdAccountId = user?.accountDetails?.id;
    const { setValidatedDevices } = useDevicesContext();
    const { clearFiles } = useDeviceManager();
    const { movementActions } = useMovementContext();
    const { checkMovementActions } = useMovement();
    const { data: consignmentData } = useQuery<any>(ConsignmentEditQuery, { id, envdAccountId }, { fetchPolicy: 'network-only', fetchKey: id });
    const shouldFetchDevices = Number(consignmentData?.consignment?.numOfAddedDevices) > 0;
    const { data: devicesData } = useQuery<DeviceGqlQuery>(
        ValidatedDeviceListQuery,
        { consignmentNumber: id, envdAccountId },
        { fetchPolicy: 'network-only', fetchKey: id, skip: !shouldFetchDevices }
    );

    useEffect(() => {
        // clear all device related data before laoding the page so new data can be loaded
        clearFiles();
        if (devicesData) {
            setValidatedDevices({
                value: new Set<ValidatedDevice>(
                    devicesData?.devices?.value?.map((device) => ({
                        nLISID: device?.nLISID ?? '',
                        rFID: device?.rFID ?? '',
                        registeredTo: device?.registeredTo ?? '',
                        species: device?.species ?? '',
                        status: device?.status ?? '',
                        deceased: device?.deceased ?? false,
                    }))
                ),
                totalDevices: Number(devicesData?.devices?.totalDevices) ?? 0,
            });
        }
    }, [clearFiles, devicesData, setValidatedDevices]);

    useEffect(() => {
        if (consignmentData) {
            const { consignment } = consignmentData;
            const actions = movementActions && movementActions[consignment?.number];
            if (consignment && !actions) {
                checkMovementActions(consignment, user!);
            }
        }
    }, [consignmentData, movementActions, checkMovementActions, user, devicesData, setValidatedDevices]);

    // Handle the rendering logic
    if (!consignmentData) {
        // Show loader until consignmentData is available
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }

    const { consignment } = consignmentData;

    if (containsDeprecatedForms(consignment)) {
        // Redirect to consignments if forms are deprecated
        history.replace('/consignments');
        return null;
    }

    let cloneConsignment = _.clone(consignment);

    if (consignment?.destination?.pic === UNKNOWN_PIC) {
        cloneConsignment = cleanConsignmentPIC(cloneConsignment, 'destination', '');
    }

    if (consignment?.numOfAddedDevices > 0) {
        // Show loader until devicesData is available
        if (!devicesData) {
            return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
        }
    }

    // Render DeviceMovementInfo when all conditions are met
    return <DeviceMovementInfo consignment={cloneConsignment} />;
};

export default DevicesMovement;

import { ErrorIcon, FailedIcon, SuccessIcon } from '@assets/icons';
import Tooltip from '@components/Form/Tooltip';
import HoverableIcon from '@components/HoverableIcon';
import React from 'react';

type ValidationStatus = 'success' | 'warning' | 'error';

interface ValidationCardProps {
    status: ValidationStatus;
    issueCount?: number;
}

/**
 * Card component for Device Validation information.
 * @param props
 */
const ValidationCard: React.FC<ValidationCardProps> = ({ status, issueCount }) => {
    const getStatusDetails = () => {
        const errorText = issueCount === 1 ? 'error' : 'errors';
        const warningText = issueCount === 1 ? 'warning' : 'warnings';

        switch (status) {
            case 'error':
                return {
                    title: `A total of ${issueCount} ${errorText} detected`,
                    description:
                        'Devices with errors cannot be transferred onto PICs. We recommend reviewing and correcting them beforehand or contacting NLIS Support 1800 683 111 for further clarification.',
                    icon: <HoverableIcon Icon={FailedIcon} defaultColor="#CB3727" hoverColor="#943B2E" />,
                    tooltip: "Validation found errors, this specific tag won't transfer. Please replace or delete it.",
                };
            case 'warning':
                return {
                    title: `A total of ${issueCount} ${warningText} detected`,
                    description:
                        'Devices with warnings can be transferred onto PICs. We recommend reviewing and correcting them beforehand or contacting NLIS Support 1800 683 111 for further clarification.',
                    icon: <HoverableIcon Icon={ErrorIcon} defaultColor="#FDB714" hoverColor="#BA8827" />,
                    tooltip: "Validation has some warnings. These won't stop the transfer, but resolving them beforehand is helpful.",
                };
            case 'success':
            default:
                return {
                    title: 'Good News: No issues detected.',
                    description:
                        'All devices have been validated, and no errors or warnings were found. Once you verify that the eID devices and livestock numbers are correct you can proceed and move the livestock onto your PIC.',
                    icon: <HoverableIcon Icon={SuccessIcon} defaultColor="#008A07" hoverColor="#16704A" />,
                    tooltip: 'Validation completed successfully with no error, or warnings. All in order, allowing you to proceed smoothly with the transfer.',
                };
        }
    };

    const { title, description, icon, tooltip } = getStatusDetails();

    return (
        <>
            <div className={`flex-row box flex-between ${status} m-v-16`}>
                <div className="flex-row flex-between transfer-content p-v-16 ">
                    <div className="icon-container p-v-4">
                        <Tooltip text={tooltip}>{icon}</Tooltip>
                    </div>
                    <div className="content-container">
                        <h2>{title}</h2>
                        <p className="p-t-8 m-b">{description}</p>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border-radius: 4px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .icon-container {
                    flex: 0 0 60px;
                    display: flex;
                    justify-content: center;
                }

                .content-container {
                    flex-grow: 1;
                    text-align: left;
                }

                .transfer-content {
                    width: 100%;
                }

                .success {
                    border: 2px solid $color-success;
                }

                .warning {
                    border: 2px solid $color-warn;
                }

                .error {
                    border: 2px solid $color-error;
                }
            `}</style>
        </>
    );
};

export default ValidationCard;

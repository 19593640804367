import { ExportIcon, FilterIcon, InfoIcon, RevalidateIcon } from '@assets/icons';
import Button from '@components/Button';
import Input from '@components/Form/Input';
import ToggleSwitch from '@components/Form/ToggleSwitch';
import Tooltip from '@components/Form/Tooltip';
import HoverableIcon from '@components/HoverableIcon';

const DeviceTableFilters: React.FC = () => {
    return (
        <>
            <div className="filter-container">
                <div className="button-group">
                    <Button className="buttonText" buttonType="tertiary">
                        <RevalidateIcon />
                        <span className="m-h-8">Revalidate</span>
                    </Button>
                    <Button className="buttonText" buttonType="tertiary">
                        <ExportIcon />
                        <span className="m-h-8">Export</span>
                    </Button>
                    <Button buttonType="tertiary">
                        <FilterIcon />
                        <span className="m-h-8">Filter</span>
                    </Button>
                </div>
                <div>
                    <Input
                        value={''}
                        onChange={(e) => console.log('Handle search state')}
                        maxLength={20}
                        placeholder="Search by NLISID or RFID"
                        errorText={'Please enter a name (Business or Individual)'}
                        data-cy="name-field"
                        className="filter-input"
                    />
                </div>
            </div>
            <div className="m-v-16 flex-container">
                <div className="align-items-center">
                    <span className="midbold p-h-8">Show issues only</span>
                    <span style={{ position: 'relative', top: '6px' }}>
                        <Tooltip placement="top" text="Enable 'show issues only' to display tags with issues. To view all tags again, simply turn off this option.">
                            <HoverableIcon Icon={InfoIcon} defaultColor="#627CA2" hoverColor="#436290" />
                        </Tooltip>
                    </span>
                    <span className="p-h-16" style={{ position: 'relative', top: '-4px' }}>
                        <ToggleSwitch onChange={() => console.log('Handle toggle state')} />
                    </span>
                </div>
            </div>
            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                .filter-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .filter-input {
                    width: 600px;
                }

                .buttonText {
                    color: blue;
                    border: 1px solid blue;
                }

                .button-group {
                    display: flex;
                    gap: 8px;
                }

                .filter-button {
                    padding: 8px 16px;
                    border: 1px solid #ccc;
                    background-color: #f5f5f5;
                    cursor: pointer;
                }
            `}</style>
        </>
    );
};

export default DeviceTableFilters;

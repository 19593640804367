import { ChevronDownIcon, InfoIcon } from '@assets/icons';
import ContextMenu from '@common/components/Form/ContextMenu';
import { useUserState } from '@common/context/userContext';
import { consignmentEnumToColor, consignmentEnumToName, speciesEnumToName } from '@common/utils/enum-transformers';
import Button from '@components/Button';
import DeviceTransferTagWithTooltip from '@components/DeviceTransferTooltipTag';
import MovementTag from '@components/MovementTag';
import Tag from '@components/Tag';
import getConsignmentContextMenu from '@containers/Consignments/consignment-context-menu';
import { containsDeprecatedForms, fromPriorSystem } from '@containers/Consignments/ConsignmentHelper';
import useMovement from '@effects/useMovement';
import useRoleValidation from '@effects/useRoleValidation';
import { IUser } from '@typings';
import { MOBILE_OR_TABLET_WIDTH } from '@utils/constants';
import { RoleTypeEnum } from '@utils/enums';
import { getFormAlias } from '@utils/form-alias';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { graphql, useFragment } from 'relay-hooks';

import ConfirmModal, { ConfirmModalRef } from '../ConfirmModal';
import { DeleteConsignmentModal } from '../DeleteConsignmentModal';
import { ConsignmentRow_consignment } from './__generated__/ConsignmentRow_consignment.graphql';

const fragment = graphql`
    fragment ConsignmentRow_consignment on ConsignmentType {
        id
        number
        status
        movementDate
        movementTime
        species
        createdBy
        updatedAt
        createdAt
        pdfUrl
        heads
        buyerPic
        buyerNumOfHeads
        movementID
        movementStatus
        numOfAddedDevices
        forms {
            type
            serialNumber
        }
        owner {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }
        origin {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }
        destination {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }
        consignee {
            address {
                line1
                postcode
                state
                town
            }
            name
            pic
        }
    }
`;

interface Props {
    consignment: ConsignmentRow_consignment;
    showMovementDate: boolean;
}

const mapFormEnumToDisplayName = ({ type }: any) => getFormAlias(type)?.alias;

const ConsignmentRow: React.FC<Props> = (props) => {
    const consignment = useFragment(fragment, props.consignment as any);
    const [{ user }] = useUserState();
    const { hasRoleWithConsignment, hasRole } = useRoleValidation();
    const isBuyer = hasRole(RoleTypeEnum.BUYER);
    const deleteModalRef = useRef<ConfirmModalRef>();
    const printModalRef = useRef<ConfirmModalRef>();
    const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE_OR_TABLET_WIDTH });
    const isDesktop = !isTabletOrMobile;

    const history = useHistory();

    const { getDeviceTransferStatus } = useMovement();

    const { checkMovementActions } = useMovement();

    useEffect(() => {
        checkMovementActions({ ...consignment, type: 'CONSIGNMENT' } as any, user!);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consignment, user]);

    if (!consignment) {
        // This could be an optimistically deleted consignment
        return null;
    }

    const isIncomingConsignment = user && user.accountDetails.pic === consignment.destination.pic;
    const showMovementTag = user?.accountDetails?.isPicBased;
    const movementDate = new Date(consignment.movementDate as string);

    const fromPrior = fromPriorSystem(consignment);
    const containsDeprecated = containsDeprecatedForms(consignment);
    const consignmentSupported = !fromPrior && !containsDeprecated;

    function getTagType(consignment: ConsignmentRow_consignment, user: IUser): ('incoming' | 'outgoing') | undefined {
        if (hasRoleWithConsignment(user.accountDetails, RoleTypeEnum.PRODUCER, consignment)) {
            return 'outgoing';
        }
        if (hasRoleWithConsignment(user.accountDetails, RoleTypeEnum.RECEIVER, consignment)) {
            return 'incoming';
        }
    }

    const hasDevices = consignment.numOfAddedDevices > 0;
    const hasMovementId = Boolean(consignment.movementID);
    const deviceTransferStatus = getDeviceTransferStatus(hasMovementId, hasDevices, movementDate, consignment.status);

    return (
        <div className={classnames('tbl-row', { 'tbl-unsupported': !consignmentSupported })} key={consignment.number}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .tbl-row {
                    .tbl-cell-30 {
                        div {
                            width: 100%;
                        }
                    }

                    @media (max-width: $md-max) {
                        border: 1px solid $color-line;
                        border-radius: $border-radius;
                        background: $color-white;
                        margin: grid(4) 0;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 80%);
                        }
                    }
                }

                .tbl-unsupported {
                    // background-color: $color-warn;
                }

                .title {
                    :global(.Button--Link) {
                        text-align: left;
                    }
                }

                .cell-content {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-content: flex-start;
                    :global(.Button--Link) {
                        justify-content: flex-start;
                        margin-top: grid(1);

                        :global(svg) {
                            width: grid(4);
                            height: grid(4);
                        }
                    }
                }

                .responsive-heading {
                    @media (max-width: $md-max) {
                        flex-direction: column;
                        align-items: flex-start !important;

                        .mobile-header-options {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                        }
                    }
                }
            `}</style>

            <div className="tbl-cell-30 responsive-heading" data-cy="consignment-summary">
                {isTabletOrMobile && (
                    <>
                        <div className="mobile-header-options">
                            <div>
                                <Tag tagType={consignmentEnumToColor(consignment.status)} text={consignmentEnumToName(consignment.status)} />
                            </div>
                            <ContextMenu options={getConsignmentContextMenu(consignment as any, user, deleteModalRef, printModalRef, false, history)} icon={<ChevronDownIcon />} buttonText="Options" />
                        </div>
                    </>
                )}
                <div>
                    <div className="title m-b-4">
                        <Button
                            buttonType={'link'}
                            onClick={() => {
                                if (!consignmentSupported) {
                                    printModalRef.current!.show();
                                } else {
                                    history.push(`/consignments/summary/${consignment.number}`);
                                }
                            }}
                        >
                            <div className="flex-start-row">
                                {!consignmentSupported && (
                                    <Button buttonType="link" buttonSize="xsmall" warn style={{ marginTop: '0px' }}>
                                        <InfoIcon style={{ minWidth: '23px' }} alt="Info" />
                                    </Button>
                                )}
                                {consignment.origin && consignment.origin.name && `${consignment.origin.name} `}
                                {!isIncomingConsignment && consignment.destination && consignment.destination.name && `to ${consignment.destination.name}`}
                            </div>
                        </Button>
                    </div>
                    <div className="muted small">
                        {consignment.number} - Last updated {new Date(consignment.updatedAt).relativeTime()}
                    </div>
                </div>
            </div>
            {showMovementTag && (
                <div className="tbl-cell-15">
                    <div className="cell-title"></div>
                    <div className="cell-content">
                        <MovementTag tagType={getTagType(consignment, user)}></MovementTag>
                    </div>
                </div>
            )}

            {isBuyer && (
                <div className="tbl-cell-15" data-cy="movement-date">
                    <div className="cell-title">Destination PIC</div>
                    <div className="cell-content">{consignment?.buyerPic || ''}</div>
                </div>
            )}

            {props.showMovementDate && (
                <div className="tbl-cell-15" data-cy="movement-date">
                    <div className="cell-title">Movement Date</div>
                    <div className="cell-content">{movementDate ? movementDate.toMLADateString() : ''}</div>
                </div>
            )}

            <div className="tbl-cell-15" data-cy="livestock">
                {isBuyer ? <div className="cell-title">Livestock Bought</div> : <div className="cell-title">Livestock</div>}
                <div className="cell-content">
                    {isBuyer ? consignment?.buyerNumOfHeads : consignment.heads && consignment.heads > 0 && consignment.heads} {speciesEnumToName(consignment.species)}
                </div>
            </div>

            <div className="tbl-cell-15" data-cy="forms">
                <div className="cell-title">Forms</div>
                <div className="cell-content">{consignment.forms?.map(mapFormEnumToDisplayName).join(', ')}</div>
            </div>

            {isDesktop && (
                <div className="tbl-cell-15" data-cy="status">
                    <div className="cell-title">Status</div>
                    <div className="cell-content">
                        <Tag tagType={consignmentEnumToColor(consignment.status)} text={consignmentEnumToName(consignment.status)} />
                    </div>
                </div>
            )}

            <div className="tbl-cell-15" data-cy="tag-transfer">
                <div className="cell-title">Tag Transfer</div>
                <div className="cell-content">{<DeviceTransferTagWithTooltip status={deviceTransferStatus} placement={isDesktop ? 'left' : 'top'} />}</div>
            </div>

            {isDesktop && (
                <div className="tbl-cell-15 flex-end-desktop">
                    <ContextMenu
                        options={getConsignmentContextMenu({ ...consignment, type: 'CONSIGNMENT' } as any, user, deleteModalRef, printModalRef, false, history)}
                        icon={<ChevronDownIcon />}
                        buttonText="Options"
                    />
                </div>
            )}

            <DeleteConsignmentModal consignment={{ ...consignment, type: 'CONSIGNMENT' }} ref={deleteModalRef} />
            <ConfirmModal
                actions={[
                    {
                        style: 'primary',
                        text: 'View',
                        action: () => {
                            window.open(consignment.pdfUrl, 'oldConsignment');
                            printModalRef.current!.hide();
                        },
                    },
                    {
                        style: 'secondary',
                        text: 'Close',
                        action: () => {
                            printModalRef.current!.hide();
                        },
                    },
                ]}
                ref={printModalRef}
                cancelText="Close"
                modalId={`confirm-modal`}
                title={`This consignment can only be viewed`}
            >
                <p>This consignment was created using an old form version and is view-only.</p>
            </ConfirmModal>
        </div>
    );
};

export default ConsignmentRow;

import * as React from 'react';
const SvgRevalidate = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props} viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M8.9974 2.2526C10.9391 2.2526 12.6807 3.1026 13.8974 4.43594L11.9141 6.41927L16.9141 6.41927L16.9141 1.41927L15.0807 3.2526C13.5641 1.61927 11.4057 0.585937 8.9974 0.585937C4.6724 0.585937 1.12239 3.8776 0.705728 8.08594L2.38906 8.08594C2.7974 4.8026 5.60573 2.2526 8.9974 2.2526ZM8.9974 17.2526C13.3224 17.2526 16.8724 13.9609 17.2891 9.7526L15.6057 9.7526C15.1974 13.0359 12.3891 15.5859 8.9974 15.5859C7.05573 15.5859 5.31406 14.7359 4.0974 13.4026L6.08073 11.4193L1.08073 11.4193L1.08073 16.4193L2.91406 14.5859C4.43073 16.2193 6.58906 17.2526 8.9974 17.2526Z"
        />
    </svg>
);
export default SvgRevalidate;


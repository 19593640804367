import { ValidatedDevice } from '@common/context/DevicesContext';
import Loader from '@components/Loader';
import { ConsignmentDetailQueryResponse } from '@containers/Consignments/__generated__/ConsignmentDetailQuery.graphql';
import { useWindowHeight } from '@effects/useWindowHeight';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import DeviceRow from './DeviceRow';


export const Header: React.FC = () => {

    return (
        <div className={'tbl-row row-header'}>
            <div className="tbl-cell-10 column-heading">Validation</div>
            <div className="tbl-cell-20 column-heading">NLISID</div>
            <div className="tbl-cell-20 column-heading">RFID</div>
            <div className="tbl-cell-15 column-heading">Livestock</div>
            <div className="tbl-cell-15 column-heading">Registered PIC</div>
            <div className="tbl-cell-15 column-heading">Device Status</div>
            <div className="tbl-cell-10 column-heading">Deceased</div>
            <div className="tbl-cell-15 column-heading" />
        </div>
    );
};


interface DeviceTableProps {
    validatedDevices: ValidatedDevice[];
    consignment: ConsignmentDetailQueryResponse['consignment']
}
const DeviceTable: React.FC<DeviceTableProps> = ({ validatedDevices, consignment }) => {
    const [displayDevices, setDisplayDevices] = useState<ValidatedDevice[]>([]);
    const [hasMoreDevices, setHasMoreDevices] = useState(true);
    const height = useWindowHeight();
    const ITEMS_PER_PAGE = 10;

    useEffect(() => {
        const initialItems = validatedDevices.slice(0, ITEMS_PER_PAGE);
        setDisplayDevices(initialItems);
        setHasMoreDevices(validatedDevices.length > ITEMS_PER_PAGE);
    }, [validatedDevices]);

    const loadMore = (page: number) => {
        const startIndex = (page - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;

        const newItems = validatedDevices.slice(startIndex, endIndex);
        setDisplayDevices((prevItems) => [...prevItems, ...newItems]);

        if (endIndex >= validatedDevices.length) {
            setHasMoreDevices(false); // No more items to load
        }
    };

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
                .tbl {
                    @media (max-width: $md-max) {
                        border: none;
                        background: transparent;
                    }
                }
            `}</style>

            <div className="tbl tbl-collapse">
                <Header />
                <div
                    style={{
                        width: '100%',
                        height: height, // Set fixed height
                        overflowY: 'auto', // Enable vertical scrolling
                    }}
                >
                    <InfiniteScroll
                        className="tbl-body"
                        data-cy="consignment-records"
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={hasMoreDevices}
                        loader={
                            <div className="tbl-row" key={0}>
                                <Loader isLoading={true} error={''} retry={() => null} timedOut={false} pastDelay={false} />
                            </div>
                        }
                        useWindow={false}
                    >
                        {displayDevices?.length === 0 ? (
                            <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />
                        ) : (
                            displayDevices?.map((device: ValidatedDevice) => {
                                return <DeviceRow key={device!.nLISID ?? device!.rFID} validatedDevice={device!} consignment={consignment} />;
                            })
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
};

export default DeviceTable;

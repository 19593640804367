import { ResetIcon, WarningIcon } from '@assets/icons';
import Button from '@components/Button';
import MultilineTextArea from '@components/Form/MultilineTexArea';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import useDeviceManager from '@effects/useDeviceManager';
import { useWindowWidth } from '@effects/useWindowWidth';
import React, { ReactNode, useRef } from 'react';

interface ManualDeviceInputProps {
    children?: ReactNode;
}
const ManualDeviceInput: React.FunctionComponent<ManualDeviceInputProps> = ({ children }) => {
    const width = useWindowWidth();
    const { rawMnaualDeviceIds, deviceIds, processManualDevices, errorMessage, clearFiles } = useDeviceManager();
    const confirmSubmitRef = useRef<ConfirmModalRef>();
    // const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        processManualDevices(value);
    };

    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';
                    .upload-container {
                        width: 70%;
                        min-width: 300px;
                    }
                    .added-device-number {
                        color: $unselected-tab-text
                    }

                    
                    .warning-icon {
                        :global(svg) {
                            width: $icon-lg;
                            height: $icon-lg;
                            color: $color-error;
                        }
                    }
                    
                    .gap {
                        gap: 10px;
                        font-size: 16px;
                    }
                `}
            </style>
            <div className={`${width > 740 ? 'flex-start-row' : 'flex-center-column'} m-v-32`} style={{ width: '100%', gap: 15 }}>
                {children}
                <div className='flex-end-column' style={{ width: '100%', maxWidth: '820px' }}>
                    <MultilineTextArea rows={10} labelText={'NLISID or RFID'} value={rawMnaualDeviceIds} onChange={handleChange} errorText={errorMessage} isValid={errorMessage?.length === 0} />
                    {errorMessage?.length === 0 && deviceIds?.size > 0 && <p className='added-device-number' >{deviceIds?.size} added</p>}

                    {deviceIds?.size > 0 && (
                        <div className='flex-start-row' style={{ width: '100%' }}>
                            <Button buttonType="reset" buttonSize="small" onClick={() => confirmSubmitRef.current?.show()}>
                                <div className="flex-center-row flex-between gap">
                                    Reset
                                    <ResetIcon />
                                </div>
                            </Button>
                        </div>
                    )}
                </div>
                <ConfirmModal
                    actions={[
                        {
                            style: 'secondary',
                            text: 'Cancel',
                            buttonSize: 'full-width',
                            action: async () => {
                                confirmSubmitRef.current?.hide();
                            },
                        },
                        {
                            style: 'delete',
                            text: 'Reset',
                            buttonSize: 'full-width',
                            action: async () => {
                                clearFiles();
                                confirmSubmitRef.current?.hide();
                            },
                        },
                    ]}
                    ref={confirmSubmitRef}
                    modalId={`confirm-modal`}
                    title={
                        <div className="flex-center-row warning-icon gap" style={{ gap: 10, fontSize: 16 }}>
                            <WarningIcon /> Reset
                        </div>
                    }
                >
                    <p>Reset will remove all current devices from this consignment, you will no longer be able to view it on your device or web. </p>
                    <p>Are you sure you want to proceed with this action?</p>
                </ConfirmModal>
            </div>
        </>
    );
};

export default ManualDeviceInput;

import Button from '@components/Button';
import React from 'react';

import Banner from './Banner';

/**
 * Reusable card component for Movement info.
 * @param props
 */
const MovementCard = ({
    title,
    description,
    icon,
    buttonText,
    buttonAction,
    isTabletOrMobile,
}: {
    title: string;
    description: string;
    icon: React.ReactNode;
    buttonText: string;
    buttonAction: () => void;
    isTabletOrMobile: boolean;
}) => {
    return (
        <>
            <Banner text="Checkout this NEW functionality!">
                <div className="flex-row box new-functionality flex-between">
                    <div className="flex-row flex-between transfer-content p-v-16 p-h-16">
                        <div className="p-v-4 p-h-12">{icon}</div>
                        <div className="content-container">
                            <h2>{title}</h2>
                            <p className="p-v-8">{description}</p>
                        </div>
                        <div className="flex-center-row p-l-8">
                            <Button className="print-button" buttonType="secondary" onClick={buttonAction} buttonSize={isTabletOrMobile ? 'small' : 'normal'}>
                                {buttonText}
                            </Button>
                        </div>
                    </div>
                </div>
            </Banner>

            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                .box {
                    box-sizing: border-box;
                    background-color: $color-white;
                    border: 1px solid $grey-border;
                    border-radius: 4px;
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .new-functionality {
                    border-radius: 0 4px 4px 4px;
                }

                .content-container {
                    flex-grow: 1;
                    text-align: left;
                }

                .transfer-content {
                    width: 100%;
                }
            `}</style>
        </>
    );
};

export default MovementCard;
